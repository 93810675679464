import React from 'react';
import { Container, Row, Col, Breadcrumb} from "react-bootstrap";
import Header from "../../components/HeaderPro";
import Footer from "../../components/FooterPro";
import {Helmet} from "react-helmet";
import {Link} from 'gatsby'
import PageWrapper from "../../components/PageWrapper";
import { Post } from "../../components/Core";
import DataTable from '../../components/data/banquePro/banquePro'
import DataTableMobile from '../../components/data/banquePro/banqueProMobile'
import Disclosure from '../../components/Disclosure'
import {StaticImage} from 'gatsby-plugin-image';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers, faUser, faStore, faCity} from '@fortawesome/free-solid-svg-icons'

function Accounting(props) {

	var d = new Date();
var month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
var n = month[d.getMonth()];
var y = d.getFullYear();

    return (
        <PageWrapper footerDark>
          <Helmet>
                <meta charSet="utf-8" />
                <title>Comparatif Banque Pro - Classement 2021</title>
                <meta name="description" content="Trouvez la meilleure banque pro avec un comparatif tarifs, cartes bancaires et comptabilité intégrée" />
            </Helmet>
          <Header/>
          <Container fluid style={{background: 'white', marginTop: 68, paddingTop: 20, paddingBottom: 130}}>
          <Container style={{maxWidth: 1280, marginTop: 28}}>
         
          <Breadcrumb>
            <Breadcrumb.Item> <Link to={`/`} style={{color: 'rgb(41, 49, 61)', fontWeight: 300}}>Accueil</Link></Breadcrumb.Item>
            <Breadcrumb.Item> <Link to={`/banque-pro/`} style={{color: 'rgb(41, 49, 61)', fontWeight: 300}}>Banque Pro</Link></Breadcrumb.Item>
            <Breadcrumb.Item active style={{ color: 'rgb(189 190 191)', fontWeight: 300}}>Comparatif</Breadcrumb.Item>
          </Breadcrumb>
  

          <h1 id='compareh1' style={{color: 'rgb(41, 49, 61)'}}>Comparatif Banque Pro - Classement 2021</h1>
          <h2 id='compareh2' style={{color: 'rgb(41, 49, 61)'}}>Trouvez la meilleure banque pro avec un comparatif tarifs, cartes bancaires et comptabilité intégrée (mise à jour {n} {y})</h2>
          <div id='onetwothreemobile'>
          <p id='onep' style={{color: 'rgb(41, 49, 61)'}}><span id='one' style={{background: 'rgb(229 34 79)', paddingLeft: 10, paddingRight: 10, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>1</span> - Utilisez le menu de notre <span style={{fontWeight: 700}}>comparateur banques pro</span> pour choisir votre statut : employé.e.s, tarifs, services inclus.</p>
          <p id='twop' style={{color: 'rgb(41, 49, 61)'}}><span id='two' style={{background: 'rgb(229 34 79)', paddingLeft: 8, paddingRight: 9, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>2</span> - Comparez les offres par tarif de carte bancaire, et les offres promos disponibles dès aujourd’hui.</p>
          <p id='threep' style={{color: 'rgb(41, 49, 61)'}}><span id='three' style={{background: 'rgb(229 34 79)', paddingLeft: 8, paddingRight: 9, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>3</span> - Sélectionnez la <span style={{fontWeight: 700}}>meilleure banque pro</span>, celle qui correspond le plus à vos besoins.</p>
          </div>

          <Disclosure/>
          <Row style={{height: 70, marginTop: -7, marginBottom: -50}}>
          
          </Row>
          </Container>
          </Container>  


     
          <Container style={{maxWidth: 1280, marginTop: -130}}>
        
          <Tabs style={{maxWidth: 1234}}>
    <TabList style={{marginLeft: 5}}>
      <Tab style={{marginLeft: 0, textAlign: 'center'}}><FontAwesomeIcon icon={faUser} style={{marginTop: 0, marginBottom: 0, fontSize: 20, marginLeft: 5, marginRight: 10}}/> Auto-Entrepreneur </Tab>
      <Tab style={{marginLeft: 0, textAlign: 'center'}}><FontAwesomeIcon icon={faUsers} style={{marginTop: 0, marginBottom: -3, fontSize: 24, marginLeft: 5, marginRight: 10}}/> SA / SAS / SASU</Tab>
      <Tab style={{marginLeft: 0, textAlign: 'center'}}><FontAwesomeIcon icon={faStore} style={{marginTop: 0, marginBottom: -3, fontSize: 24, marginLeft: 5, marginRight: 10}}/> SARL / EURL</Tab>
      <Tab style={{marginLeft: 0, textAlign: 'center'}}><FontAwesomeIcon icon={faCity} style={{marginTop: 0, marginBottom: -3, fontSize: 24, marginLeft: 5, marginRight: 10}}/> SCI / EIRL</Tab>
      {/* <Tab style={{marginLeft: 0, textAlign: 'center'}}><FontAwesomeIcon icon={faCity} style={{marginTop: 0, marginBottom: -3, fontSize: 24, marginLeft: 5, marginRight: 10}}/> Association</Tab> */}
    </TabList>

    <TabPanel>
    <DataTable type='Société' what='autoentrepreneur' id='wideTable' classement='Note' order={true}/>
	<DataTableMobile type='Société' what='autoentrepreneur' id='mobileTable' classement='Note' order={true}/>
    </TabPanel>
      <TabPanel>      
      <DataTable type='Société' row={[12,4,18,9,19,30,20,40]} id='wideTable'/>
      <DataTableMobile type='Société' row={[12,4,18,9,19,30,20,40]} id='mobileTable'/>
    </TabPanel>
      <TabPanel>    
      <DataTable type='Société' row={[9,21,4,22,23,31,40]} id='wideTable'/>
      <DataTableMobile type='Société' row={[9,21,4,22,23,31,40]} id='mobileTable'/>
    </TabPanel>
    <TabPanel>
      <DataTable type='Société' row={[9,21,5,22,23,24,25,26,40]} id='wideTable'/>
      <DataTableMobile type='Société' row={[9,21,5,22,23,24,25,26,40]} id='mobileTable'/>
    </TabPanel>
    <TabPanel>
      <DataTable type='Société' row={[9,21,5,22,23,24,25,26,40]} id='wideTable'/>
      <DataTableMobile type='Société' row={[9,21,5,22,23,24,25,26,40]} id='mobileTable'/>
    </TabPanel>

  </Tabs>
            <Row className="justify-content-center">
              <Col>
            
       
            

       
              </Col>
            </Row>
             
          </Container>
          <Container style={{marginTop: 100, marginBottom : 100}}>
          <Post>

          <h2>Comment choisir la meilleure banque pour un auto-entrepreneur et micro-entreprise ?
			</h2><p>Si la France connaît des années records en terme de création d’entreprise, c’est principalement la micro-entreprise qui connaît la croissance la plus forte.
			</p>
<StaticImage
		  	src='../../images/banque-pro-auto-entrepreneur.jpeg'
			width={1200}
			alt='Banque pro pour autoentrepreneurs'
			placeholder='tracedSVG'
			id='pickky'
			/>
			<p>
Chaque micro-entreprise doit absolument détenir un compte bancaire pro indépendant de sa banque personnelle.
</p>

<h3>Une micro-entreprise (auto-entrepreneur), c’est quoi ?</h3>
<p>
Le régime des micro-entrepreneurs est destiné aux entreprises individuels (EI). Depuis 2017, les auto-entrepreneurs et les micro-entrepreneurs sont réunis sous le statut de micro-entreprise.
</p><p>
Si le statut de micro-entreprise est très avantageux, il faudra faire attention à bien respecter les différents plafonds de chiffre d’affaire et ainsi que les seuils de franchise TVA qui compliquera votre comptabilité.
</p>
<p>
Plafonds de CA :
</p>
<ul>
	<li>Achat / revente de marchandises : plafonds CA 176 000€</li>
	<li>Ventes de denrées à consommer sur place : plafonds CA 176 000€</li>
	<li>Prestations d’hébergement : plafonds CA 176 000€</li>
	<li>Prestations de service commerciales ou artisanales : plafonds CA 72 000€</li>
	<li>Activités libérales : plafonds CA 72 000€</li>
	
</ul>

<p>Seuils de franchise TV :</p>

<ul>
	<li>Achat / revente de marchandises : 85 800€</li>
	<li>Ventes de denrées à consommer sur place : 85 800€</li>
	<li>Prestations d’hébergement : 85 800€</li>
	<li>Prestations de service commerciales ou artisanales : 34 400€</li>
	<li>Activités libérales : 34 400€</li>

</ul>


<h3>La meilleure banque pro pour le liquide ?</h3>
<p>
C’est un moyen de paiement qui tend à disparaître, mais si vous encaissez beaucoup de paiement en espèce, nous n’aurez pas le choix. La banque traditionnelle est pour l’instant toujours à favoriser si vous avez beaucoup de transactions sous forme de liquide.
</p><h3>
Banque pro pour les chèques</h3>
<p>
Certaines activités comme le secteur de la construction a encore beaucoup recours aux chèques. La néobanque Qonto est pour l’instant la seule néobanque à accepter pleinement ce type de paiement.
</p>
<h3>
Pas de chèque ou de liquide ? Choisissez vraiment la meilleure banque pro!</h3>
<p>
Pour toutes les activités principalement basés sur le paiement en ligne ou les virements, l’offre bancaire est la plus large pour les micro-entrepreneurs et auto-entrepreneurs.
</p>
<ul>
	<li>Banque en ligne</li>
	<li>Néobanques pros</li>
	<li>Banques traditionnelles</li>
</ul>


          <h2>Comment choisir la meilleure banque pour votre SAS ou SASU ?</h2>
      

{/* <p style={{marginTop: 50}}>
    <iframe src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1127930317&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true" width="100%" height="150" frameborder="no" scrolling="no" title='website builders'></iframe>
      </p>  */}
      
<p>
Si il s’agit d’une création, vous avez probablement choisit le statut SAS et SASU pour votre entreprise pour vous permettre de grandir en toute liberté.
</p><p>
Attention, cette liberté peut partir en fumée si vous vous associez à une banque pro trop rigide, coûteuse et qui n’offre pas les services permettant une gestion quotidienne simplifiée de votre société.
</p>
<StaticImage
		  	src='../../images/meilleure-banque-pro-sas-sasu.jpeg' width={1200}
			alt='meilleure banque pour sasu'
			placeholder='tracedSVG'
			/>

<p>
Dans un premier temps il est important de déterminer quels types d’opérations bancaires vous allez avoir besoin. Et ces opérations dépendant de la gestion de votre entreprise aujourd’hui ainsi que dans son future proche.
</p>
<h3>Quelle est la taille de vos transactions ?</h3>
<p>
On peut naturellement penser que si vous avez des fonds disponibles, vous pourrez les dépenser.. Ce n’est pas le cas ! Les plafonds de votre banque peuvent paralyser votre activité.
</p><p>
Avez-vous régulièrement besoin de faire des transactions avec des montants élevés ?
</p>

<h3>Règle numéro 1 d’une entreprise : être payé !</h3>
<ul>
<li>Avez-vous besoin de déposer de l’espèce ?</li>
<li>Avez-vous besoin d’encaisser des chèques ?
</li>
<li>Avez-vous besoin d’un terminal de paiement ?</li>
<li>Recevez-vous des paiements sur votre site internet / eCommerce ?</li>
</ul>

<h3>Vous et vos collaborateurs</h3>
<p>Le paiement par carte bancaire de vous ou votre équipe impactera votre entreprise de 2 manières :</p>
<ul>
  <li>Vous devez garder le contrôle sur les dépenses</li>
  <li>Chaque dépense aura besoin d’un justificatif pour la comptabilité</li>
</ul>
<p>
Avez-vous besoin de donner des cartes de paiements à vos collaborateurs ?</p>
<p>
Selon la banque, chaque carte bancaire supplémentaire peut avoir des frais de fabrication ainsi que des coûts de gestion. Certaines offres proposent des packs multi-cartes ou encore des accès à des cartes virtuelles.
</p><p>
Avez-vous ou vos collaborateurs beaucoup de notes de frais ?
</p>

<h3>Comptabilité</h3>
<ul>
  <li>Allez-vous avoir besoin de faire des augmentations de capital ?</li>
  <li>Souhaitez-vous avoir l’option de donner un accès direct à vos comptes à votre comptable ?</li>
</ul>


<h3>Quelle est votre relation avec l’international ?</h3>
<p>
C’est surtout au niveau des voyages d’affaires hors de la zone Euro que l’addition peut être salé si vous ne choisissez pas la meilleure banque.
</p>
<ul>
  <li>Faites-vous beaucoup de virement en euros ?</li>
  <li>Avez-vous besoin de faire beaucoup de virement en devises ?</li>
  <li>Votre équipe voyage t-elle hors de la zone euro pour le business ?</li>
</ul>

<h2>Comment choisir la meilleure banque pour une EURL ou une SARL ?</h2>
<p>La société de type SARL est le statut d’entreprise préféré des entreprises familiales. C’est une forme particulièrement intéressante pour le statut de conjoint collaborateur qui donne des avantages à la personne mariée ou pacsée du chef d’entreprise.
</p>
<StaticImage
		  	src='../../images/banque-pro-sarl-eurl.jpeg'
			width={1200}
			alt='Banque pro sarl eurl'
			placeholder='tracedSVG'
			id='pickky'
			/>
<p>Si la SAS connaît la plus forte croissance pour les nouvelles créations, la SARL représente près de 50% de toutes les sociétés crées en France.
</p><p>
Le quotidien d’une entreprise est déjà assez difficile. Trouver la meilleure banque pro pour votre activité vous permettra d’enlever le poid des tâches administratives non nécessaires.
</p><p>
Plusieures questions à vous poser pour mieux choisir votre banque :
</p>
<ul>
	<li>Acceptez-vous le paiement en liquide ?</li>
	<li>Avez-vous besoin d’encaisser de nombreux chèques ?</li>
	<li>Avez-vous besoin d’un terminal de paiement ?</li>
	<li>Les reçus et les notes de frais font-elle partie votre quotidien ?</li>
</ul>
<h2>Comment choisir la meilleure banque pour une SCI ?</h2>
			<p>
La SCI est le statut d’entreprise de choix pour des investissements immobiliers dans le but d’acheter à plusieurs et/ou de préparer la transmission de biens à vos enfants.
</p><p>
<StaticImage
		  	src='../../images/banque-pro-sci.jpeg'
			width={1200}
			alt='Banque à distance'
			placeholder='tracedSVG'
			id='pickky'
			/>
C’est un statut qui n’est pas accepté par toutes les banques. Ce comparatif vous permettra de faire un tri rapide dans les offres du marché, et trouver rapidement une banque qui vous aidera dans vos investissements.
</p>




</Post>

          </Container>
          <Footer/>
      </PageWrapper>
    )
}

export default Accounting
